var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-0",attrs:{"maxHeight":_vm.height,"minHeight":_vm.minHeight}},[_c('v-card-title',{staticClass:"secondary white--text py-0",on:{"mouseover":function($event){return _vm.setShowConfig(true)},"mouseleave":function($event){return _vm.setShowConfig(false)}}},[_vm._v(" Задачи по графику ("+_vm._s(_vm.data.length)+") "),_c('v-spacer'),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfig),expression:"showConfig"}],attrs:{"small":""},on:{"click":function($event){return _vm.$emit('config', _vm.modelConfig)}}},[_vm._v("fas fa-wrench")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"mt-0 pa-1 pt-0"},[(_vm.loading)?_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"loader"})]):_vm._e(),(_vm.data.length > 0)?_c('div',[_c('v-simple-table',{attrs:{"fixed-header":"","dense":"","height":_vm.height - 36},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"main-table-header"},[_c('tr',[_c('th',{staticClass:"text-left px-1"}),_c('th',{staticClass:"text-left px-1"},[_vm._v("Заголовок")]),_c('th',{staticClass:"text-left px-1"},[_vm._v("Объект")]),_c('th',{staticClass:"text-left px-1"},[_vm._v("Дэдлайн")])])]),_c('tbody',_vm._l((_vm.dataSort),function(el,i){return _c('tr',{key:i,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('click', el)}}},[_c('td',{staticClass:"text-left px-1"},[_c('a-view-table-chip',{attrs:{"value":el.is_important,"model":{
                    hideText: true,
                    showText: el.id,
                    options:
                      _vm.$store.getters['config/get'].models.tasks
                        .optionsIsImportant,
                  }}})],1),_c('td',{staticClass:"text-left px-1"},[(el.status == 100)?_c('v-chip',{staticClass:"px-1",attrs:{"x-small":"","color":"error"}},[_vm._v(" new ")]):_vm._e(),_c('a-view-table-taskname',{attrs:{"value":el.name,"values":el}})],1),_c('td',{staticClass:"text-left px-1"},[_c('a-view-table-string',{attrs:{"value":el.object_name}})],1),_c('td',{staticClass:"text-left px-1"},[_c('a-view-table-date',{attrs:{"value":el.date_end}})],1)])}),0)]},proxy:true}],null,false,4161975889)})],1):_c('div',[_vm._v("Список пуст")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }