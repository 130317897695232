<template>
  <v-card class="ma-0" :maxHeight="height" :minHeight="minHeight">
    <v-card-title
      class="secondary white--text py-0"
      @mouseover="setShowConfig(true)"
      @mouseleave="setShowConfig(false)"
    >
      Задачи по графику ({{ data.length }})
      <v-spacer />
      <v-icon small v-show="showConfig" @click="$emit('config', modelConfig)"
        >fas fa-wrench</v-icon
      >
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-0 pa-1 pt-0">
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-if="data.length > 0">
        <v-simple-table fixed-header dense :height="height - 36">
          <template v-slot:default>
            <thead class="main-table-header">
              <tr>
                <th class="text-left px-1"></th>
                <th class="text-left px-1">Заголовок</th>
                <th class="text-left px-1">Объект</th>
                <th class="text-left px-1">Дэдлайн</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(el, i) in dataSort"
                :key="i"
                @click="$emit('click', el)"
                style="cursor: pointer"
              >
                <td class="text-left px-1">
                  <a-view-table-chip
                    :value="el.is_important"
                    :model="{
                      hideText: true,
                      showText: el.id,
                      options:
                        $store.getters['config/get'].models.tasks
                          .optionsIsImportant,
                    }"
                  />
                </td>
                <td class="text-left px-1">
                  <v-chip
                    x-small
                    v-if="el.status == 100"
                    color="error"
                    class="px-1"
                  >
                    new
                  </v-chip>
                  <a-view-table-taskname :value="el.name" :values="el" />
                </td>
                <td class="text-left px-1">
                  <a-view-table-string :value="el.object_name" />
                </td>
                <td class="text-left px-1">
                  <a-view-table-date :value="el.date_end" />
                </td>
              </tr>
            </tbody> </template
        ></v-simple-table>
      </div>
      <div v-else>Список пуст</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    height: { type: Number, default: 0 },
    rows: Array,
  },
  data() {
    return {
      contentHeight: 10,
      idEdit: 0,
      loading: false,
      data: [],
      showConfig: false,
      timeID: null,

      modelConfig: [
        {
          name: "taskToday.work.beforeDay",
          title: "дней до задачи",
          type: "string",
        },
      ],
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
    dataSort() {
      return this.data.sort((a, b) => {
        return (
          (b.status == 100) - (a.status == 100) ||
          (a.date_start > b.date_start) - (a.date_start < b.date_start)
        );
      });
    },
    filters() {
      const f = this.$store.getters["config/get"].config.taskToday;
      let res = Object.assign(f.work.filters, {
        /*       date_start: {
          condition: "<=",
          value: new Date().date,
        },
 */ date_end: {
          condition: ">=",
          value: new Date().date,
        },
      });
      return res;
    },
  },
  methods: {
    setShowConfig(e) {
      clearTimeout(this.timeID);
      this.timeID = setTimeout(() => (this.showConfig = e), 200);
    },

    async fitchData() {
      this.loading = true;
      if (this.rows) {
        this.data = this.rows;
      } else {
        const filters = this.filters;
        const resp = await this.$axios.get("/mechti/tasks", {
          params: {
            filters,
          },
        });
        //console.log("work", resp);
        const dayBefore =
          this.$store.getters["config/getLocalParams"][
            "taskToday.work.beforeDay"
          ] || 0;
        const now = new Date();
        let data = resp.data.data.filter((el) => {
          return (
            new Date(el.date_start).addDays(-dayBefore) < now ||
            new Date(el.date_start) < now
          );
        });

        this.data = data;
      }
      this.loading = false;
      if (this.data.length == 0) {
        this.$emit("hide"); //this.$parent.hideBlock('todayWork');
      }
    },
  },
};
</script>